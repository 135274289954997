import React from "react";
import { graphql } from "gatsby";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import TrustedBy from "Commons/TrustedBy";
import TrainingsList from "Components/Trainings/TrainingsList";
import TrainingTitle from "Components/Trainings/TrainingTitle";
import { Helmet } from "react-helmet";
import useLang from "Commons/Hooks/useLang";

const TrainingsPage = ({ data }) => {
  const [lang] = useLang();
  const { pageTitle, trainingTitle } =
    data.allContentfulTrainingTexts.nodes[lang];
  const { companiesThatTrust } = data.allContentfulCommonTexts.nodes[lang];
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header noScrollEffect />
      <section className="padded__section">
        <TrainingTitle title={trainingTitle} />
        <TrainingsList />
      </section>
      <TrustedBy text={companiesThatTrust} />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getTrainingsPageText {
    allContentfulTrainingTexts {
      nodes {
        trainingTitle
        pageTitle
      }
    }
    allContentfulCommonTexts {
      nodes {
        companiesThatTrust
      }
    }
  }
`;

export default TrainingsPage;
